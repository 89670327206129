import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, Input, Button, Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useIntercom } from 'react-use-intercom';
import * as yup from 'yup';

import { useSubmitAuthFormMutation } from '@Entities/signup/api/signupSlice';
import { ga4event } from '@Shared/utils/ga4event';
import useYup from '@Shared/utils//useYup';
import useLocalStorage from '@Shared/utils/useLocalStorage';

type AuthFormDataType = {
    firstName: string;
    email: string;
    password: string;
    notificationsAgree?: boolean;
    termsAgree: boolean;
};

export default function AuthForm({ handleStep }: { handleStep: (arg1: number) => void }) {
    const storage = useLocalStorage();
    const { trackEvent } = useIntercom();
    const [submitAuthForm, { isLoading }] = useSubmitAuthFormMutation();
    const [errors, setErrors] = useState<Partial<AuthFormDataType>>({});
    const [form] = Form.useForm();
    const yupRules = useYup({
        firstName: yup.string().required('Please enter your name.'),
        email: yup.string().required('Please enter your email.').email('This value is not a valid email address.'),
        password: yup
            .string()
            .required('Please enter your password.')
            .min(8, 'This value is too short. It should have 8 characters or more.'),
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement> | CheckboxChangeEvent) => {
        const fieldName = e.target?.id?.replace('auth-form_', '');
        ga4event({
            event_name: `enter_${fieldName}`,
            event_option1: 'advertiser registration funnel',
            event_option2: 'filled name email password',
        });
        setErrors((prev: Partial<AuthFormDataType>) =>
            Object.fromEntries(
                Object.entries(prev).filter(([key]: [key: string, value: unknown]) => key !== fieldName),
            ),
        );
    };

    const handleSubmit = async (formData: AuthFormDataType) => {
        try {
            const res = await submitAuthForm(formData);
            if (res?.error) {
                const errors = (res?.error?.data?.errors as AuthFormDataType) || {};
                ga4event({
                    event_name: 'send_advertiser_signup_lead_success_f',
                    event_option1: 'advertiser registration funnel',
                    event_option2: 'filled name email password',
                    event_option4: `error ${Object.values(errors).join(' ')}`,
                });
                trackEvent('lead-from-signup-step-1', {
                    eventMessage: 'auth step error',
                    formData,
                });

                setErrors(errors);
            } else {
                ga4event({
                    event_name: 'send_advertiser_signup_lead_success_f',
                    event_option1: 'advertiser registration funnel',
                    event_option2: 'filled name email password',
                });

                trackEvent('lead-from-signup-step-1', {
                    eventMessage: 'auth step success',
                    formData,
                });

                storage.write('currentStep', 'CompanyStep');
                storage.write('stepUuid', res?.data?.uuid || '');
                storage.write('authStepData', formData || {});
                handleStep(1);
            }
        } catch (err) {
            console.error('error: ', err);
        }
    };

    return (
        <Form
            initialValues={storage.read('authStepData', {
                firstName: '',
                email: '',
                password: '',
            })}
            name="auth-form"
            layout="vertical"
            form={form}
            onFinish={handleSubmit}
            requiredMark={false}
        >
            <Form.Item
                label="Name"
                name="firstName"
                rules={yupRules}
                validateStatus={errors?.firstName ? 'error' : ''}
                help={errors?.firstName || null}
            >
                <Input
                    size="large"
                    onBlur={handleChange}
                    className="signup-input"
                />
            </Form.Item>
            <Form.Item
                label="Email"
                name="email"
                rules={yupRules}
                validateStatus={errors?.email ? 'error' : ''}
                help={errors?.email || null}
            >
                <Input
                    size="large"
                    onBlur={handleChange}
                    className="signup-input"
                    onInput={(e: React.FormEvent<HTMLInputElement>) =>
                        e.target.value ? (e.target.value = e.target.value.toLowerCase()) : ''
                    }
                />
            </Form.Item>
            <Form.Item
                label="Password"
                name="password"
                rules={yupRules}
                extra={!errors?.password ? 'Your password should include at least 8 characters or more.' : null}
                validateStatus={errors?.password ? 'error' : ''}
                help={errors?.password || null}
            >
                <Input.Password
                    size="large"
                    onBlur={handleChange}
                    className="signup-input"
                />
            </Form.Item>
            <Form.Item
                className="item-custom-checkbox"
                rootClassName="item-custom-root-checkbox"
                name="notificationsAgree"
                validateStatus={errors?.notificationsAgree ? 'error' : ''}
                help={errors?.notificationsAgree || null}
                valuePropName="checked"
            >
                <Checkbox
                    className="checkbox-custom"
                    onChange={handleChange}
                >
                    I agree to receive notifications about the product, news and special offers.
                </Checkbox>
            </Form.Item>
            <Form.Item
                name="termsAgree"
                validateStatus={errors?.termsAgree ? 'error' : ''}
                help={errors?.termsAgree || null}
                valuePropName="checked"
                rules={[
                    {
                        required: true,
                        transform: (value) => value || undefined,
                        type: 'boolean',
                        message: 'You must agree to our policies to proceed.',
                    },
                ]}
            >
                <Checkbox onChange={handleChange}>
                    I accept Tapfiliate&apos;s{' '}
                    <Link
                        relative="path"
                        to="https://tapfiliate.com/terms/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Terms and Conditions
                    </Link>{' '}
                    and{' '}
                    <Link
                        relative="path"
                        to="https://tapfiliate.com/privacy/privacy-policy/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Privacy Policy
                    </Link>{' '}
                    and agree to receive product news and relevant educational content. Tapfiliate takes privacy very
                    seriously.{' '}
                    <Link
                        relative="path"
                        to="https://tapfiliate.com/general-data-protection-regulation-gdpr/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Learn more about the General Data Protection Regulation.{' '}
                    </Link>
                </Checkbox>
            </Form.Item>
            <Form.Item style={{ margin: 0 }}>
                <Button
                    block
                    htmlType="submit"
                    type="primary"
                    style={{ textTransform: 'uppercase', fontWeight: 600 }}
                    size="large"
                    loading={isLoading}
                >
                    Try it for free
                </Button>
            </Form.Item>
        </Form>
    );
}
