import React from 'react';
import { Typography, Grid } from 'antd';

import { CompanyForm } from '@Features/signin-forms';
import useLocalStorage from '@Shared/utils/useLocalStorage';

const { Title, Paragraph } = Typography;
const { useBreakpoint } = Grid;

const styles: Record<string, React.CSSProperties> = {
    main: {
        maxWidth: 'min(calc(100vw - 20px), 620px)',
        width: 'fit-content',
        height: 'fit-content',
        borderRadius: 'var(--ant-border-radius-lg)',
        boxShadow: 'var(--ant-box-shadow)',
    },
    bg: {
        position: 'fixed',
        zIndex: -1,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
    },
};

export default function CompanyStep({ handleStep }: { handleStep: (arg1: number) => void }) {
    const storage = useLocalStorage();
    const breakpoints = useBreakpoint();
    const firstName = storage.read('authStepData', {}).firstName || '';

    return (
        <div style={{ ...styles.main, padding: breakpoints.lg ? '40px 60px' : 20 }}>
            <Title
                level={3}
                style={{ marginBottom: 20 }}
            >
                Welcome, {firstName}!
            </Title>

            <Paragraph>
                Before you get started, we have a few questions to you that will help us customise your amazing
                Tapfiliate journey 🚀
            </Paragraph>

            <CompanyForm handleStep={handleStep} />
        </div>
    );
}
