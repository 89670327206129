import React, { ReactNode } from 'react';
import { ConfigProvider, theme } from 'antd';
import { useFeatureFlagEnabled } from 'posthog-js/react';

const { useToken } = theme;

const customTokens = {
    textColor: '#052D57',
    colorSurfaceBlueBg: '#D4E8FF',
    colorSurfaceLightBlueCard: '#F2F7FF',
    colorButtonDefaultBlue: '#0085FF',
};

export default function Theme({ children }: { children: ReactNode }) {
    const { token } = useToken();

    const showWelcomeMessage = useFeatureFlagEnabled('new-sign-up');
    showWelcomeMessage;

    return (
        <ConfigProvider
            theme={{
                hashed: false,
                cssVar: true,
                token: {
                    colorPrimary: customTokens.colorButtonDefaultBlue,
                    colorText: customTokens.textColor,
                    colorTextHeading: customTokens.textColor,
                    colorPrimaryBg: '#FEFAEE',
                    fontFamily: `Montserrat, ${token.fontFamily}`,
                    fontSizeHeading3: 38,
                    lineHeightHeading3: 1.3,
                    fontSizeHeading4: 28,
                    lineHeightHeading4: 1.3,
                    fontWeightStrong: 700,
                    borderRadiusLG: 12,
                },
                components: {
                    Select: {
                        optionSelectedBg: customTokens.colorSurfaceLightBlueCard,
                        optionActiveBg: customTokens.colorSurfaceBlueBg,
                    },
                    Typography: {
                        titleMarginBottom: 0,
                        titleMarginTop: 0,
                    },
                    Button: {
                        colorPrimary: customTokens.colorButtonDefaultBlue,
                        defaultBorderColor: customTokens.colorButtonDefaultBlue,
                        defaultColor: customTokens.colorButtonDefaultBlue,
                        algorithm: true,
                        paddingBlockLG: 28,
                        contentFontSizeLG: 16,
                        contentLineHeightLG: 1.4,
                    },
                    Input: {
                        colorPrimary: customTokens.colorButtonDefaultBlue,
                        colorTextPlaceholder: '#8DB4E1',
                        colorBorder: '#8DB4E1',
                        algorithm: true,
                    },
                },
            }}
        >
            {children}
        </ConfigProvider>
    );
}
