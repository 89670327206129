import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from '@Shared/assets/translations/en.json';

const languages = [
    {
        key: 'en',
        label: 'English',
    },
    {
        key: 'de',
        label: 'Deutsch',
    },
    {
        key: 'es',
        label: 'Español',
    },
    {
        key: 'fr',
        label: 'Français',
    },
    {
        key: 'pt',
        label: 'Português',
    },
];

const DEFAULT_LANG = languages[0].key;
const langs2LoadMore = languages.map((l) => l.key).slice(1);

try {
    i18n.use(initReactI18next).init({
        lng: DEFAULT_LANG,
        fallbackLng: DEFAULT_LANG,
        returnEmptyString: false,
        resources: {
            en: {
                translation: translationEN,
            },
        },
        interpolation: {
            escapeValue: false,
        },
        react: {
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'b'],
        },
    });
} catch (e: unknown) {
    console.error(e);
}

function removeEmpty(obj: object): object {
    return Object.fromEntries(
        Object.entries(obj)
            .filter(([, v]) => v != null)
            .map(([k, v]: [string, unknown]) => [k, v === Object(v) ? removeEmpty(v as object) : v]),
    );
}

(function loadTranslations() {
    langs2LoadMore.forEach(async (lang: string) => {
        await import(`@Shared/assets/translations/${lang}.json`)
            .then((data) => {
                const dataNoEmpty = removeEmpty(data);
                i18n.addResourceBundle(lang, 'translation', dataNoEmpty);
            })
            .catch(() => 'no show error');
    });
})();

export { languages };

export default i18n;
