import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import { SigninPage } from '@Pages';

export const router = createBrowserRouter([
    {
        path: '/',
        element: <SigninPage />,
    },
    {
        path: '/blackfriday/',
        element: <SigninPage />,
    },
    {
        path: '/lovely-user/winback/',
        element: <SigninPage />,
    },
]);
