import React, { useMemo, useState, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Select, Flex, Typography, Row, Col, Space, Grid } from 'antd';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { TrialForm } from '@Features/index';
import { AdvCard } from '@Widgets/index';
import { Horn, Avatar2 } from '@Shared/assets/images';
import {
    useLazyGetPlansQuery,
    useLazyGetWinbackPlansQuery,
    useLazyGetBlackFridayPlansQuery,
} from '@Entities/signup/api/signupSlice';
import { ga4event } from '@Shared/utils/ga4event';
import './styles.less';

const { Title, Paragraph } = Typography;
const { useBreakpoint } = Grid;

const customTokens = {
    colorSurfaceBlueBg: '#D4E8FF',
    colorSurfaceLightBlueCard: '#F2F7FF',
    colorButtonDefaultBlue: '#0085FF',
};

const styles: Record<string, React.CSSProperties> = {
    main: {
        maxWidth: 'min(calc(100vw - 20px), 1400px)',
        width: 'fit-content',
        height: 'fit-content',
        boxShadow: 'var(--ant-box-shadow)',
    },
    left: {
        padding: '40px 60px',
    },
    buttonsSpace: {
        width: '100%',
    },
    topLogo: {
        position: 'fixed',
        top: '25px',
        marginLeft: '-60px',
        width: 162,
    },
    orDivider: {
        margin: 10,
        textAlign: 'center',
    },
    em: {
        color: customTokens.colorButtonDefaultBlue,
        fontStyle: 'normal',
    },
    bg: {
        position: 'fixed',
        zIndex: -1,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
    },
    trialContainer: {
        height: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
    },
};

type PlanType = {
    value?: string;
    cycle?: string;
    title: string;
    trialDays: string;
    price: string;
    id: string;
    currency?: string;
};

export default function TrialStep({ handleStep }: { handleStep: (arg1: number) => void }) {
    const [activePlan, setActivePlan] = useState<PlanType | object>({});
    const location = useLocation();
    const [fetchPlans, { isLoading: isLoadingPlans, data: dataPlans }] = useLazyGetPlansQuery();
    const [fetchWinbackPlans, { isLoading: isLoadingWinbackPlans, data: dataWinbackPlans }] =
        useLazyGetWinbackPlansQuery();
    const [fetchBlackfridayPlans, { isLoading: isLoadingBlackFridayPlans, data: dataBlackFridayPlans }] =
        useLazyGetBlackFridayPlansQuery();
    const [searchParams] = useSearchParams();
    const breakpoints = useBreakpoint();

    const isWinback = useMemo(() => location.pathname.includes('lovely-user/winback'), [location.pathname]);
    const isBlackfriday = useMemo(() => location.pathname.includes('blackfriday'), [location.pathname]);

    const planOptions: PlanType[] = useMemo(
        () => (isWinback ? dataWinbackPlans : isBlackfriday ? dataBlackFridayPlans : dataPlans)?.plans || [],
        [dataPlans, dataWinbackPlans, dataBlackFridayPlans, isBlackfriday, isWinback],
    );

    useEffect(() => {
        if (isWinback) {
            fetchWinbackPlans('');
        } else if (isBlackfriday) {
            fetchBlackfridayPlans('');
        } else {
            fetchPlans('');
        }
    }, [isWinback, isBlackfriday, fetchPlans, fetchWinbackPlans, fetchBlackfridayPlans]);

    const stripePromise = loadStripe(process.env.STRIPE_API_KEY_PUBLISHABLE || '');

    const chargeDate = useMemo(() => {
        const date = new Date();
        return new Date(
            date.setDate(date.getDate() + parseInt(activePlan?.trialDays || '14', 10)),
        ).toLocaleDateString();
    }, [activePlan]);

    const handlePlanChange = (newValue: unknown) => {
        const newPlan = planOptions.find(({ id }) => id === newValue)!;
        setActivePlan(newPlan);
        ga4event({
            event_name: 'send_advertiser_payment_method_select_plan',
            event_option1: 'advertiser registration funnel',
            event_option2: 'onboarding;select_plan',
            event_option4: JSON.stringify(newPlan),
        });
    };

    useEffect(() => {
        if (planOptions?.length) {
            const pid = searchParams.get('pid');
            if (pid) {
                const plan = planOptions.find((p) => p.id.startsWith(pid)) || {};
                setActivePlan(plan);
            } else {
                const proMonthlyPlan = planOptions.find((p) => p.title === 'Pro' && p.cycle === 'monthly');
                if (proMonthlyPlan) {
                    setActivePlan(proMonthlyPlan);
                } else {
                    setActivePlan(planOptions[0]);
                }
            }
        }
        ga4event({
            event_name: 'send_advertiser_payment_method_select_plan',
            event_option1: 'advertiser registration funnel',
            event_option2: 'onboarding;select_plan',
            event_option4: JSON.stringify(planOptions[0]),
        });
    }, [planOptions, searchParams]);

    const planOptionsWithLabel = useMemo(
        () =>
            planOptions.map(({ title, price, cycle, id, currency }) => ({
                value: id,
                label: (
                    <Title
                        level={5}
                        className="xxlSelectOptionTitle"
                        style={{ fontSize: breakpoints.md ? 20 : 16 }}
                    >
                        <div style={{ whiteSpace: 'break-spaces' }}>
                            {title} {cycle === 'yearly' ? <span className="annual-em">annual</span> : null} —{'  '}
                            {currency
                                ? new Intl.NumberFormat('en-US', {
                                      style: 'currency',
                                      currency,
                                      minimumFractionDigits: Number(price) % 1 === 0 ? 0 : 2,
                                  })
                                      .format(Number(price))
                                      .replace(',', '')
                                : price.replace(',', '')}
                            /{cycle === 'yearly' ? 'year' : 'month'}
                        </div>
                        {id.includes('propromo2024year') ? <div className="save-tag">Save 40%</div> : null}
                        {id.includes('enterprisepromo2024year') ? <div className="save-tag">Save 50%</div> : null}

                        {cycle === 'yearly' &&
                        !['enterprisepromo2024year', 'propromo2024year'].some((subStr) => id.includes(subStr)) ? (
                            <div className="save-tag">Save 17%</div>
                        ) : null}
                    </Title>
                ),
            })),
        [breakpoints.md, planOptions],
    );

    const isLoading = isLoadingPlans || isLoadingWinbackPlans || isLoadingBlackFridayPlans;

    return (
        <Row style={styles.main}>
            <Col
                lg={14}
                md={44}
                sm={24}
                xs={24}
            >
                <div style={{ ...styles.trialContainer, padding: breakpoints.lg ? '40px 60px' : 20 }}>
                    <Title
                        level={4}
                        style={{ marginBottom: 20 }}
                    >
                        {isBlackfriday
                            ? 'Black Friday Deal: Activate your 14-day free trial'
                            : 'Activate your 14-day free trial'}
                    </Title>

                    <Space
                        direction="vertical"
                        style={{
                            background: customTokens.colorSurfaceLightBlueCard,
                            borderRadius: 'var(--ant-border-radius-lg)',
                        }}
                    >
                        <Select
                            value={activePlan?.id || ''}
                            size="large"
                            className="xxlSelect"
                            options={planOptionsWithLabel}
                            onChange={handlePlanChange}
                            loading={isLoading}
                            listHeight={400}
                        />

                        <Flex
                            gap="large"
                            align="flex-start"
                            style={{
                                padding: '16px 20px',
                            }}
                        >
                            <img
                                width={34}
                                src={Horn}
                                alt="horn"
                            />
                            <Paragraph>
                                Designed for growing businesses and marketers. No worries.{' '}
                                <strong>We won&apos;t charge you today.</strong> The first payment is due on{' '}
                                {chargeDate}. We&apos;ll remind you <strong>2 days before your trial</strong> ends. You
                                can cancel it anytime.
                            </Paragraph>
                        </Flex>
                    </Space>
                    <Paragraph>&nbsp;</Paragraph>
                    <Elements stripe={stripePromise}>
                        <TrialForm
                            handleStep={handleStep}
                            activePlan={activePlan}
                            isPlansLoading={isLoading}
                        />
                    </Elements>
                </div>
            </Col>

            <Col
                lg={10}
                md={0}
                sm={0}
                xs={0}
            >
                <AdvCard
                    avatar={Avatar2}
                    author="Amber Weaver"
                    authorPosition="Founding Director at TYPE01"
                >
                    Since we started using Tapfiliate, <em style={styles.em}>the impact has been remarkable</em>.
                    Initially aiming to double our revenue, we exceeded expectations, achieving nearly{' '}
                    <em style={styles.em}>tenfold growth</em>.
                </AdvCard>
            </Col>
        </Row>
    );
}
