import React from 'react';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import { I18nextProvider } from 'react-i18next';

import i18n from '@Shared/utils/i18n';

import Page from './Page';

import Theme from '../providers/ThemeProvider';
import { router } from '../Routes';
import { store } from '../providers/redux/store';

const INTERCOM_APP_ID = process.env.INTERCOM_APP_ID || '';

const App: React.FC = () => {
    return (
        <IntercomProvider
            autoBoot
            appId={INTERCOM_APP_ID}
        >
            <I18nextProvider i18n={i18n}>
                <Provider store={store}>
                    <Theme>
                        <Page>
                            <RouterProvider router={router} />
                        </Page>
                    </Theme>
                </Provider>
            </I18nextProvider>
        </IntercomProvider>
    );
};

export default App;
