type GA4EventStrictDetailKeys = 'event_name' | 'event_option1' | 'event_option2' | 'event_option4';

export type GA4EventStrictDetailType = Record<GA4EventStrictDetailKeys, string>;

export type GA4EventDetailType = GA4EventStrictDetailType & {
    event?: string;
    event_name?: string;
    event_option1?: string;
    event_option2?: string;
    event_option4?: string;
    advertiser_id?: string;
    ab_test?: string;
    program_id?: string;
};

const defaultGA4Details = {
    event: 'ga4genericEvent',
    event_name: 'undefined',
    event_option1: 'undefined',
    event_option2: 'undefined',
    event_option4: 'undefined',
    // ab_test: 'undefined',
    // program_id: 'undefined',
};

export function ga4event(detail: Partial<GA4EventDetailType>) {
    const data = { ...defaultGA4Details, ...detail };
    try {
        window.dataLayer?.push(data);
    } catch (e: unknown) {
        console.error('ga4Event', e);
    }
}
