import React from 'react';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import { PostHogProvider } from 'posthog-js/react';

import Page from './Page';

import Theme from '../providers/ThemeProvider';
import { router } from '../Routes';
import { store } from '../providers/redux/store';

const INTERCOM_APP_ID = process.env.INTERCOM_APP_ID || '';
const POSTHOG_HOST = process.env.POSTHOG_HOST || '';
const POSTHOG_API_KEY = process.env.POSTHOG_API_KEY || '';

const postHogOptions = {
    api_host: POSTHOG_HOST,
};

const App: React.FC = () => {
    return (
        <PostHogProvider
            apiKey={POSTHOG_API_KEY}
            options={postHogOptions}
        >
            <IntercomProvider appId={INTERCOM_APP_ID}>
                <Provider store={store}>
                    <Theme>
                        <Page>
                            <RouterProvider router={router} />
                        </Page>
                    </Theme>
                </Provider>
            </IntercomProvider>
        </PostHogProvider>
    );
};

export default App;
