import { object, type ObjectShape } from 'yup';
import { Rule } from 'antd/es/form';

export default function useYup(formSchema: ObjectShape) {
    const schema = object().shape(formSchema);

    const yupSync = {
        async validator({ field }: { field: string }, value: unknown) {
            await schema.validateSyncAt(field, { [field]: value });
        },
    } as unknown as Rule;

    return [yupSync];
}
