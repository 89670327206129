import React from 'react';
import { Typography, Grid } from 'antd';
import { useTranslation } from 'react-i18next';

import { ObjectivesForm } from '@Features/signin-forms';

const { Paragraph } = Typography;
const { useBreakpoint } = Grid;

const styles: Record<string, React.CSSProperties> = {
    main: {
        maxWidth: 'min(calc(100vw - 20px), 620px)',
        width: 'fit-content',
        height: 'fit-content',
        padding: '40px 60px',
        borderRadius: 'var(--ant-border-radius-lg)',
        boxShadow: 'var(--ant-box-shadow)',
    },
    bg: {
        position: 'fixed',
        zIndex: -1,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
    },
};

export default function ObjectivesStep({ handleStep }: { handleStep: (arg1: number) => void }) {
    const breakpoints = useBreakpoint();
    const { t } = useTranslation('translation', { keyPrefix: 'signup' });

    return (
        <div style={{ ...styles.main, padding: breakpoints.lg ? '40px 60px' : 20 }}>
            <Paragraph style={{ fontSize: 18, marginTop: 38 }}>{t('objectives_step.text')}</Paragraph>

            <ObjectivesForm handleStep={handleStep} />
        </div>
    );
}
