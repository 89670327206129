import React, { useState } from 'react';
import { Form, Input, Button, Flex, Grid } from 'antd';
import * as yup from 'yup';

import { useSubmitCompanyFormMutation } from '@Entities/signup/api/signupSlice';
import { ga4event } from '@Shared/utils/ga4event';
import useYup from '@Shared/utils//useYup';
import useLocalStorage from '@Shared/utils/useLocalStorage';

const { useBreakpoint } = Grid;

type CompanyFormDataType = {
    companyName: string;
    companyLink: string;
    subdomain: string;
};

export default function CompanyForm({ handleStep }: { handleStep: (arg1: number) => void }) {
    const storage = useLocalStorage();
    const [submitCompanyForm, { isLoading }] = useSubmitCompanyFormMutation();
    const breakpoints = useBreakpoint();
    const [errors, setErrors] = useState<Partial<CompanyFormDataType>>({});
    const [form] = Form.useForm();
    const yupRules = useYup({
        companyName: yup.string().required('Please enter your company name.'),
        companyLink: yup
            .string()
            .required('Please enter website link.')
            .matches(
                /^[-a-zA-Z0-9@:%._+~#=]{1,2034}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/,
                'This value is not a valid URL.',
            ),
        subdomain: yup.string().required('Please enter Tapfiliate subdomain.'),
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const fieldName = e.target?.id?.replace('company-form_', '');
        ga4event({
            event_name: `enter_${fieldName}`,
            event_option1: 'advertiser registration funnel',
            event_option2: 'onboarding;company, website link, subdomain',
        });
        setErrors((prev: Partial<CompanyFormDataType>) =>
            Object.fromEntries(
                Object.entries(prev).filter(([key]: [key: string, value: unknown]) => key !== fieldName),
            ),
        );
    };

    const handleSubmit = async (formData: CompanyFormDataType) => {
        const modifiedFormData = {
            ...formData,
            companyLink: `https://${formData.companyLink}`,
        };
        try {
            const uuid = storage.read('stepUuid');
            const res = await submitCompanyForm({ body: modifiedFormData, id: uuid });

            if (res?.error) {
                const errors = (res?.error?.data?.errors as CompanyFormDataType) || {};
                ga4event({
                    event_name: 'send_advertiser_create_account_error_f',
                    event_option1: 'advertiser registration funnel',
                    event_option2: 'onboarding;company, website link, subdomain',
                    event_option4: `error ${Object.values(errors).join(' ')}`,
                });

                setErrors(errors);
            } else {
                ga4event({
                    event_name: 'send_advertiser_create_account_success_f',
                    event_option1: 'advertiser registration funnel',
                    event_option2: 'onboarding;company, website link, subdomain',
                });

                storage.write('currentStep', 'ObjectivesStep');
                storage.write('stepUuid', res?.data?.uuid || '');
                storage.write('companyStepData', formData || {});
                handleStep(1);
            }
        } catch (err) {
            console.error('error: ', err);
        }
    };

    return (
        <Form
            initialValues={storage.read('companyStepData', {
                companyName: '',
                companyLink: '',
                subdomain: '',
            })}
            name="company-form"
            layout="vertical"
            onFinish={handleSubmit}
            form={form}
            requiredMark={false}
        >
            <Form.Item
                label="Tell us your company name"
                name="companyName"
                rules={yupRules}
                validateStatus={errors?.companyName ? 'error' : ''}
                help={errors?.companyName || null}
            >
                <Input
                    size="large"
                    placeholder="Company Name"
                    className="signup-input"
                    onChange={(e) => {
                        form.setFieldValue(
                            'subdomain',
                            (e.target.value.toLocaleLowerCase().match(/[a-z0-9]+/g) || []).join(''),
                        );
                        handleChange(e);
                    }}
                />
            </Form.Item>
            <Form.Item
                label="Link to your website"
                name="companyLink"
                rules={yupRules}
                validateStatus={errors?.companyLink ? 'error' : ''}
                help={errors?.companyLink || null}
            >
                <Input
                    size="large"
                    addonBefore="https://"
                    placeholder="yourcompanylink.com"
                    onBlur={handleChange}
                    onInput={(e: React.FormEvent<HTMLInputElement>) =>
                        e.target.value ? (e.target.value = e.target.value.toLowerCase()) : ''
                    }
                />
            </Form.Item>
            <Form.Item
                label="Your Tapfiliate login link is ready"
                name="subdomain"
                rules={yupRules}
                validateTrigger={['onBlur', 'onFocus']}
                validateStatus={errors?.subdomain ? 'error' : ''}
                help={errors?.subdomain || null}
                className="subdomainField"
            >
                <Input
                    size="large"
                    addonBefore="https://"
                    addonAfter=".tapfiliate.com"
                    placeholder="yourcompanylink"
                    onBlur={handleChange}
                    onChange={(e) => {
                        form.setFieldValue(
                            'subdomain',
                            (e.target.value.toLocaleLowerCase().match(/[a-z0-9]+/g) || []).join(''),
                        );
                        handleChange(e);
                    }}
                />
            </Form.Item>

            <Form.Item style={{ margin: 0 }}>
                <Flex justify="flex-end">
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{
                            textTransform: 'uppercase',
                            fontWeight: 600,
                            width: breakpoints.md ? '100px' : '100%',
                        }}
                        size="large"
                        loading={isLoading}
                    >
                        Next
                    </Button>
                </Flex>
            </Form.Item>
        </Form>
    );
}
