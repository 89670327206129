import React, { useCallback, useState, useEffect } from 'react';
import { Flex, Grid } from 'antd';
import { useIntercom } from 'react-use-intercom';

import { SigninSteps } from '@Features/auth/signin';
import { Logo, Bg } from '@Shared/assets/images';
import useLocalStorage from '@Shared/utils/useLocalStorage';

const { useBreakpoint } = Grid;

import './styles.less';

const styles: Record<string, React.CSSProperties> = {
    flex: {
        minWidth: '100vw',
        minHeight: '100vh',
        backgroundColor: '#F6F9FD',
        flexDirection: 'column',
        backgroundSize: 'cover',
    },
    main: {
        boxSizing: 'border-box',
        background: '#fff',
        height: 'fit-content',
        borderRadius: 'var(--ant-border-radius-lg)',
    },
    mainWrapper: {
        width: '100%',
        justifyContent: 'center',
        overflow: 'auto',
        flex: 1,
        alignItems: 'center',
        padding: 20,
    },
    buttons: {
        position: 'fixed',
        left: '10px',
        bottom: '10px',
    },
    topLogo: {
        width: 162,
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        // position: 'fixed',
        // top: 0,
        height: '80px',
        width: '100%',
        backgroundColor: 'inherit',
        borderBottom: '1px solid #8DB4E11A',
        zIndex: 5,
    },
    headerInner: {
        display: 'flex',
        height: '80px',
        width: '100%',
        backgroundColor: 'inherit',
        maxWidth: 'min(calc(100vw - 20px), 1400px)',
    },
};

type StepType = keyof typeof SigninSteps;
const stepsKeys: StepType[] = ['AuthStep', 'CompanyStep', 'ObjectivesStep', 'TrialStep'];

export default function Signin() {
    const storage = useLocalStorage();
    const { boot } = useIntercom();
    const breakpoints = useBreakpoint();
    const [currentStep, setCurrentStep] = useState<StepType>(storage.read('currentStep', stepsKeys[0]) as StepType);
    const isCurrentAuthStep = currentStep === 'AuthStep';

    useEffect(() => {
        boot();
    }, [boot]);

    const handleStep = useCallback(
        async (direction: number) => {
            const currentStepIndex = stepsKeys.findIndex((value: string) => value === currentStep);
            const tmpStepIndex = currentStepIndex + direction;
            const newStepIndex =
                tmpStepIndex < 0 ? stepsKeys.length - 1 : tmpStepIndex === stepsKeys.length ? 0 : tmpStepIndex;
            setCurrentStep(stepsKeys[newStepIndex]);
        },
        [currentStep],
    );

    useEffect(() => {
        document.getElementById('scroll-flex')?.scroll(0, 0);
    }, [currentStep]);

    const CurrentForm = SigninSteps[currentStep];

    const header = isCurrentAuthStep ? (
        <div style={styles.header}>
            <div style={{ ...styles.headerInner, margin: breakpoints.lg ? 'auto' : '0 20px' }}>
                <img
                    src={Logo}
                    style={styles.topLogo}
                    alt="Tapfiliate"
                />
            </div>
        </div>
    ) : null;

    const displayBackgroundImage = !isCurrentAuthStep && breakpoints.lg;

    return (
        <Flex
            justify="center"
            align="center"
            style={{ ...styles.flex, backgroundImage: displayBackgroundImage ? `url(${Bg})` : 'unset' }}
        >
            {header}
            <Flex
                style={styles.mainWrapper}
                id="scroll-flex"
            >
                <div style={{ ...styles.main, margin: breakpoints.lg ? 'auto' : 'auto 20px' }}>
                    <CurrentForm handleStep={handleStep} />
                </div>
            </Flex>
        </Flex>
    );
}
