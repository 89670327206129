import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from '@Shared/assets/translations/en.json';
import translationDE from '@Shared/assets/translations/de.json';
import translationES from '@Shared/assets/translations/es.json';
import translationFR from '@Shared/assets/translations/fr.json';
import translationPT from '@Shared/assets/translations/pt.json';

const languages = [
    {
        key: 'en',
        label: 'English',
    },
    {
        key: 'de',
        label: 'Deutsch',
    },
    {
        key: 'es',
        label: 'Español',
    },
    {
        key: 'fr',
        label: 'Français',
    },
    {
        key: 'pt',
        label: 'Português',
    },
];

const getDefaultSiteLanguage = () => {
    try {
        const cookieSl = window.document.cookie
            .split(';')
            .map((s) => s.trim().split('='))
            .find((x) => x[0] === 'site-language');
        if (
            cookieSl &&
            languages
                .map((l) => l.key)
                .slice(1)
                .includes(cookieSl[1])
        ) {
            return cookieSl[1];
        }
    } catch (e) {
        return languages[0].key;
    }
    return languages[0].key;
};

const DEFAULT_LANG = getDefaultSiteLanguage();

try {
    i18n.use(initReactI18next).init({
        lng: DEFAULT_LANG,
        fallbackLng: DEFAULT_LANG,
        returnEmptyString: false,
        resources: {
            en: {
                translation: translationEN,
            },
            de: {
                translation: translationDE,
            },
            es: {
                translation: translationES,
            },
            fr: {
                translation: translationFR,
            },
            pt: {
                translation: translationPT,
            },
        },
        interpolation: {
            escapeValue: false,
        },
        react: {
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'b'],
        },
    });
} catch (e: unknown) {
    console.error(e);
}

export { languages };

export default i18n;
