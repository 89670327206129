import React, { useEffect, useState, useCallback } from 'react';
import { Form, Space, Input, Button, Flex, Grid, Typography } from 'antd';
import Icon from '@ant-design/icons';
import { useStripe, CardElement, useElements } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';

import { CreditCardIcon, PaypalIcon, StripeLogo } from '@Shared/assets/images';
import { useSubmitTrialFormMutation, usePostPaypalMutation } from '@Entities/signup/api/signupSlice';
import { ga4event } from '@Shared/utils/ga4event';
import useLocalStorage from '@Shared/utils/useLocalStorage';

import './styles.less';

const { useBreakpoint } = Grid;
const { Paragraph } = Typography;

type TrialFormDataType = {
    nameOnCard: string;
    paymentMethodId?: string;
    paymentProvider: string;
};

export default function TrialForm({
    handleStep,
    activePlan,
    isPlansLoading,
}: {
    handleStep: (arg1: number) => void;
    activePlan: object;
    isPlansLoading: boolean;
}) {
    const storage = useLocalStorage();
    const [submitTrialForm, { isLoading }] = useSubmitTrialFormMutation();
    const [postPaypal, { isLoading: paypalLoading = false }] = usePostPaypalMutation();
    const stripe = useStripe();
    const elements = useElements();
    const breakpoints = useBreakpoint();
    const [errors, setErrors] = useState<Partial<TrialFormDataType>>({});
    const [paymentType, setPaymentType] = useState('credit-card');
    const [stripeError, setStripeError] = useState<string | undefined>();

    const resetLocalStorage = useCallback(() => {
        storage.remove('currentStep');
        storage.remove('stepUuid');
        storage.remove('authStepData');
        storage.remove('companyStepData');
        storage.remove('planId');
        storage.remove('objectivesStepData');
    }, [storage]);

    useEffect(() => {
        ga4event({
            event_name: 'send_advertiser_payment_method_select_payment_method',
            event_option1: 'advertiser registration funnel',
            event_option2: 'onboarding;select_payment_method',
            event_option4: paymentType,
        });
    }, [paymentType]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const fieldName = e.target?.id?.replace('trial-form_', '');
        ga4event({
            event_name: `enter_${fieldName}`,
            event_option1: 'advertiser registration funnel',
            event_option2: 'onboarding;filled credit card',
        });
    };

    const handleCardElementChange = (event: StripeCardElementChangeEvent) => {
        setStripeError(event?.error?.message);
    };

    const submitTrial = useCallback(
        async (body: unknown, planId: unknown) => {
            setStripeError(undefined);
            try {
                const uuid = storage.read('stepUuid');
                const res = await submitTrialForm({
                    body,
                    id: uuid,
                    planId,
                });

                if (res?.error) {
                    setErrors(res?.error?.data?.errors || {});
                } else {
                    if (res?.data?.redirectLink) {
                        resetLocalStorage();
                        window.location.href = res.data.redirectLink;
                    }
                }
            } catch (err) {
                console.error('error: ', err);
            }
        },
        [submitTrialForm, storage, resetLocalStorage],
    );

    const handleSubmit = async (formData: TrialFormDataType) => {
        let modifiedFormData = formData;
        if (paymentType === 'credit-card') {
            const cardElement = elements?.getElement(CardElement);
            if (!cardElement) {
                return;
            }
            const stripeRes = await stripe?.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });
            if (stripeRes?.error) {
                ga4event({
                    event_name: 'send_advertiser_payment_method_error_f',
                    event_option1: 'advertiser registration funnel',
                    event_option2: 'onboarding;filled credit card',
                    event_option4: JSON.stringify(stripeRes.error),
                });

                setStripeError(stripeRes?.error.message);
                return;
            }
            modifiedFormData = {
                ...formData,
                paymentMethodId: stripeRes?.paymentMethod.id,
                paymentProvider: 'credit-card',
            };
            ga4event({
                event_name: 'send_advertiser_payment_method_success_f',
                event_option1: 'advertiser registration funnel',
                event_option2: 'onboarding;filled credit card',
            });

            submitTrial(modifiedFormData, activePlan.id);
        } else {
            try {
                const uuid = storage.read('stepUuid');
                const res = await postPaypal({ id: uuid, planId: activePlan.id });

                if (res?.error) {
                    const errors = res?.error?.data?.errors || {};
                    ga4event({
                        event_name: 'send_advertiser_payment_method_error_f',
                        event_option1: 'advertiser registration funnel',
                        event_option2: 'onboarding;filled paypall',
                    });

                    setErrors(errors);
                } else {
                    ga4event({
                        event_name: 'send_advertiser_payment_method_success_f',
                        event_option1: 'advertiser registration funnel',
                        event_option2: 'onboarding;filled paypall',
                    });

                    storage.write('currentStep', 'TrialStep');
                    storage.write('planId', activePlan.id);
                    resetLocalStorage();
                    window.location.href = res.data.redirectUrl;
                }
            } catch (err) {
                console.error('error: ', err);
            }
        }
    };

    const actionButtons = (
        <Flex
            justify="space-between"
            style={{ flexDirection: breakpoints.lg ? 'row' : 'column-reverse', gap: 10, marginTop: 6 }}
        >
            <Button
                style={{
                    textTransform: 'uppercase',
                    fontWeight: 600,
                    width: breakpoints.lg ? 100 : '100%',
                }}
                size="large"
                onClick={() => handleStep(-1)}
            >
                Back
            </Button>
            <Button
                type="primary"
                htmlType="submit"
                style={{ textTransform: 'uppercase', fontWeight: 600 }}
                size="large"
                disabled={Boolean(paymentType === 'credit-card' && stripeError) || isPlansLoading}
                loading={isLoading || paypalLoading}
            >
                Subscribe & Start FREE
            </Button>
        </Flex>
    );

    return (
        <>
            <Space
                direction="horizontal"
                style={{ marginBottom: 20 }}
            >
                <Button
                    size="large"
                    onClick={() => setPaymentType('credit-card')}
                    className={paymentType !== 'credit-card' ? 'payment-type__button-inactive' : ''}
                    icon={
                        <Icon
                            component={() => (
                                <img
                                    src={CreditCardIcon}
                                    alt="credit-card"
                                />
                            )}
                        />
                    }
                >
                    Credit Card
                </Button>
                <Button
                    size="large"
                    onClick={() => setPaymentType('paypal')}
                    className={paymentType !== 'paypal' ? 'payment-type__button-inactive' : ''}
                    icon={
                        <Icon
                            component={() => (
                                <img
                                    src={PaypalIcon}
                                    alt="paypal"
                                />
                            )}
                        />
                    }
                >
                    PayPal
                </Button>
            </Space>
            {paymentType === 'credit-card' ? (
                <Form
                    name="trial-form"
                    layout="vertical"
                    onFinish={handleSubmit}
                    requiredMark={false}
                >
                    <Form.Item
                        label="Name on card"
                        name="nameOnCard"
                        rules={[{ required: true, message: 'Please enter name on card' }]}
                        validateStatus={errors?.nameOnCard ? 'error' : ''}
                        help={errors?.nameOnCard || null}
                    >
                        <Input
                            size="large"
                            onBlur={handleChange}
                            className="signup-input"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Card Number"
                        validateStatus={errors?.paymentProvider ? 'error' : ''}
                        help={errors?.paymentProvider || null}
                        extra={
                            <>
                                All credit-card data is securely processed through our payment partner{' '}
                                <Icon
                                    style={{ marginLeft: '2px', verticalAlign: 'bottom' }}
                                    component={() => (
                                        <img
                                            src={StripeLogo}
                                            alt="Stripe"
                                        />
                                    )}
                                />
                            </>
                        }
                    >
                        <div className="stripe-card-element">
                            <CardElement
                                onChange={handleCardElementChange}
                                options={{
                                    style: {
                                        base: {
                                            fontSize: '16px',
                                        },
                                    },
                                }}
                            />
                        </div>
                        {stripeError ? (
                            <div
                                id="trial-form_stripe_help"
                                className="ant-form-item-explain ant-form-css-var ant-form-item-explain-connected"
                                role="alert"
                            >
                                <div className="ant-form-item-explain-error">{stripeError}</div>
                            </div>
                        ) : null}
                    </Form.Item>
                    <Form.Item style={{ margin: 0 }}>{actionButtons}</Form.Item>
                </Form>
            ) : (
                <>
                    <Paragraph>
                        After you click &apos;Subscribe & Start FREE&apos;, PayPal&apos;s website will open. Add there
                        your payment method data.
                    </Paragraph>
                    <Form
                        name="paypal-form"
                        layout="vertical"
                        onFinish={handleSubmit}
                        style={{ marginTop: 'auto' }}
                        requiredMark={false}
                    >
                        {actionButtons}
                    </Form>
                </>
            )}
        </>
    );
}
