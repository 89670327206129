import CryptoJS from 'crypto-js';
import { v4 as uuidv4 } from 'uuid';

const PasswordGenerator = (length: number = 12): string => {
    const randomUUID = uuidv4();
    const hash: string = CryptoJS.MD5(randomUUID).toString();

    return hash.slice(0, length);
};

export default PasswordGenerator;
