import React, { useState } from 'react';
import { Form, Radio, Button, Flex, Grid, RadioChangeEvent } from 'antd';

import { useSubmitObjectivesFormMutation } from '@Entities/signup/api/signupSlice';
import { ga4event } from '@Shared/utils/ga4event';
import useLocalStorage from '@Shared/utils/useLocalStorage';

const { useBreakpoint } = Grid;

type ObjectivesFormDataType = {
    objectives: string;
};

const options = [
    {
        value: 'new',
        label: (
            <>
                I&apos;m new to the affiliate marketing and don&apos;t have any affiliates yet, but I am eager to start.
            </>
        ),
    },
    {
        value: 'experienced',
        label: (
            <>
                I&apos;m experienced in affiliate marketing and already have a program with affiliates, but need to
                automate the process.
            </>
        ),
    },
    { value: 'migrate', label: <>I&apos;m looking to migrate from another platform.</> },
    {
        value: 'explore',
        label: (
            <>
                I&apos;m just exploring affiliate marketing softwares and do not intend to make any changes in the near
                future.
            </>
        ),
    },
];

export default function ObjectivesForm({ handleStep }: { handleStep: (arg1: number) => void }) {
    const storage = useLocalStorage();
    const [submitObjectivesForm, { isLoading }] = useSubmitObjectivesFormMutation();
    const [errors, setErrors] = useState<Partial<ObjectivesFormDataType>>({});
    const breakpoints = useBreakpoint();
    const [form] = Form.useForm();

    const handleSubmit = async () => {
        try {
            const uuid = storage.read('stepUuid');
            const res = await submitObjectivesForm({
                body: { objectives: form.getFieldValue('objectives') },
                id: uuid,
            });

            if (res?.error) {
                const errors = res?.error?.data?.errors || {};
                ga4event({
                    event_name: 'send_advertiser_expertise_level_error_f',
                    event_option1: 'advertiser registration funnel',
                    event_option2: 'onboarding;expertise level',
                    event_option4: `error ${Object.values(errors).join(' ')}`,
                });

                setErrors(errors);
            } else {
                ga4event({
                    event_name: 'send_advertiser_expertise_level_success_f',
                    event_option1: 'advertiser registration funnel',
                    event_option2: 'onboarding;expertise level',
                    event_option4: 'undefined',
                });

                storage.write('currentStep', 'TrialStep');
                storage.write('stepUuid', res?.data?.uuid || '');
                storage.write('objectivesStepData', { objectives: form.getFieldValue('objectives') });
                handleStep(1);
            }
        } catch (err) {
            console.error('error: ', err);
        }
    };

    const handleObjectiveChange = (e: RadioChangeEvent) => {
        ga4event({
            event_name: 'click_expertise_level',
            event_option1: 'advertiser registration funnel',
            event_option2: 'onboarding;expertise level',
            event_option4: `selected level is ${e.target.value}`,
        });

        form.setFieldValue('objectives', e.target.value);
    };

    const initialValues = storage.read('objectivesStepData', {
        objectives: 'new',
    });

    return (
        <Form
            initialValues={initialValues}
            form={form}
            name="objectives-form"
            layout="vertical"
            onFinish={handleSubmit}
            requiredMark={false}
        >
            <Form.Item
                name="objectives"
                validateStatus={errors?.objectives ? 'error' : ''}
                help={errors?.objectives || null}
                valuePropName="checked"
            >
                <Radio.Group
                    name="objectives"
                    options={options}
                    defaultValue={initialValues.objectives}
                    className="round-checkboxes"
                    onChange={handleObjectiveChange}
                />
            </Form.Item>

            <Form.Item style={{ margin: 0 }}>
                <Flex
                    justify="space-between"
                    style={{ flexDirection: breakpoints.lg ? 'row' : 'column-reverse', gap: 10 }}
                >
                    <Button
                        style={{ textTransform: 'uppercase', fontWeight: 600, width: breakpoints.lg ? 100 : '100%' }}
                        size="large"
                        onClick={() => handleStep(-1)}
                    >
                        Back
                    </Button>
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{ textTransform: 'uppercase', fontWeight: 600, width: breakpoints.lg ? 100 : '100%' }}
                        size="large"
                        loading={isLoading}
                    >
                        Next
                    </Button>
                </Flex>
            </Form.Item>
        </Form>
    );
}
