import React from 'react';
import { Typography, Row, Col, Grid } from 'antd';
import { useTranslation } from 'react-i18next';

import { AuthForm } from '@Features/index';
import { AdvCard } from '@Widgets/index';
import { Avatar } from '@Shared/assets/images';

const { Title } = Typography;
const { useBreakpoint } = Grid;

const customTokens = {
    colorSurfaceBlueBg: '#D4E8FF',
    colorButtonDefaultBlue: '#0085FF',
};

const styles: Record<string, React.CSSProperties> = {
    main: {
        maxWidth: 'min(calc(100vw - 20px), 1400px)',
        width: 'fit-content',
        height: 'fit-content',
    },
    buttonsSpace: {
        width: '100%',
    },
    orDivider: {
        margin: 10,
        textAlign: 'center',
    },
    em: {
        color: customTokens.colorButtonDefaultBlue,
        fontStyle: 'normal',
    },
};

export default function AuthStep({ handleStep }: { handleStep: (arg1: number) => void }) {
    const { t } = useTranslation('translation', { keyPrefix: 'signup' });
    const breakpoints = useBreakpoint();
    const isBlackfriday = location.pathname.includes('blackfriday');

    return (
        <Row style={styles.main}>
            <Col
                lg={14}
                md={24}
                sm={24}
                xs={24}
            >
                <div style={{ padding: breakpoints.lg ? '40px 60px' : 20 }}>
                    <Title
                        level={4}
                        style={{ marginBottom: 20 }}
                    >
                        {isBlackfriday ? <>{t('auth_step.title_blackfriday')}</> : <>{t('auth_step.title')}</>}
                    </Title>

                    <AuthForm handleStep={handleStep} />
                </div>
            </Col>

            <Col
                lg={10}
                md={0}
                sm={0}
                xs={0}
            >
                <AdvCard
                    avatar={Avatar}
                    author={t('auth_step.adv_card.author')}
                    authorPosition={t('auth_step.adv_card.author_position')}
                >
                    <em style={styles.em}>{t('auth_step.adv_card.text.0')}</em> {t('auth_step.adv_card.text.1')}
                </AdvCard>
            </Col>
        </Row>
    );
}
