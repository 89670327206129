import React from 'react';
import { Typography, Image } from 'antd';

import { Quote } from '@Shared/assets/images';

const { Title, Paragraph } = Typography;

const customTokens = {
    colorSurfaceBlueBg: '#D4E8FF',
    colorButtonDefaultBlue: '#0085FF',
};

const styles: Record<string, React.CSSProperties> = {
    card: {
        borderRadius: '0 var(--ant-border-radius-lg) var(--ant-border-radius-lg) 0',
        padding: '40px 60px',
        width: '100%',
        height: '100%',
        maxWidth: '100vw',
        boxSizing: 'border-box',
        background: `${customTokens.colorSurfaceBlueBg} url("${Quote}") no-repeat 40px 180px`,
    },
    authorParagraph: {
        fontSize: 14,
        marginTop: 40,
    },
    author: {
        fontWeight: 600,
        fontStyle: 'normal',
    },
    emAuthor: {
        color: customTokens.colorButtonDefaultBlue,
        fontWeight: 500,
        fontStyle: 'normal',
    },
    divider: {
        display: 'inline-block',
        marginLeft: '0.4rem',
        marginRight: '0.4rem',
    },
};

export type AdvCardProps = {
    avatar?: string;
    children?: React.ReactNode;
    author: string;
    authorPosition: string;
};

export default function AdvCard({ avatar, children, author, authorPosition }: AdvCardProps) {
    return (
        <div style={styles.card}>
            <Image
                preview={false}
                src={avatar}
                alt={author}
            />

            <Title
                level={3}
                style={{ margin: '20px 0' }}
            >
                {children}
            </Title>
            <Paragraph style={styles.authorParagraph}>
                <span style={styles.author}>{author}</span>
                <span style={styles.divider}>{'  /  '}</span>
                <em style={styles.emAuthor}>{authorPosition}</em>
            </Paragraph>
        </div>
    );
}
