/* eslint-disable @typescript-eslint/no-explicit-any */
import { Middleware } from '@reduxjs/toolkit';
import { notification } from 'antd';

export const rtkQueryErrorLogger: Middleware = () => (next) => async (action: any) => {
    if (action.type.endsWith('/rejected')) {
        const status = action?.payload?.status;
        if (status === 400) {
            return next(action);
        }
        notification.error({
            message: status ? `An error occurred with status code ${status}` : 'An error occurred',
        });
    }

    return next(action);
};
