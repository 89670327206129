import React from 'react';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';

import Page from './Page';

import Theme from '../providers/ThemeProvider';
import { router } from '../Routes';
import { store } from '../providers/redux/store';

const INTERCOM_APP_ID = process.env.INTERCOM_APP_ID || '';

const App: React.FC = () => {
    return (
        <IntercomProvider
            autoBoot
            appId={INTERCOM_APP_ID}
        >
            <Provider store={store}>
                <Theme>
                    <Page>
                        <RouterProvider router={router} />
                    </Page>
                </Theme>
            </Provider>
        </IntercomProvider>
    );
};

export default App;
