import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';

export const apiSlice = createApi({
    reducerPath: 'signup',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.SIGNUP_BASE_URL,
        credentials: 'include',
        mode: 'cors',
        prepareHeaders: (headers) => {
            headers.set('Accept', 'application/json');
            return headers;
        },
    }),
    tagTypes: ['Post'],
    endpoints: (builder) => ({
        submitAuthForm: builder.mutation({
            query: (body) => ({
                url: 'api-stateful/signup/step-auth/',
                method: 'POST',
                body: body,
            }),
        }),
        submitCompanyForm: builder.mutation({
            query: ({ body, id }) => ({
                url: `/api-stateful/signup/${id}/company/`,
                method: 'POST',
                body: body,
            }),
        }),
        submitObjectivesForm: builder.mutation({
            query: ({ body, id }) => ({
                url: `/api-stateful/signup/${id}/objectives/`,
                method: 'POST',
                body: body,
            }),
        }),
        submitTrialForm: builder.mutation({
            query: ({ body, id, planId }) => ({
                url: `/api-stateful/signup/${id}/trial/${planId}/`,
                method: 'POST',
                body: body,
            }),
        }),
        postPaypal: builder.mutation({
            query: ({ id, planId }) => ({
                url: `/api-stateful/signup/${id}/paypal/${planId}/`,
                method: 'POST',
            }),
        }),
        getPlans: builder.query({
            query: () => ({
                url: '/api-stateful/signup/plans/',
            }),
        }),
        getWinbackPlans: builder.query({
            query: () => ({
                url: '/api-stateful/signup/winback-plans/',
            }),
        }),
    }),
});

export const {
    useSubmitAuthFormMutation,
    useSubmitCompanyFormMutation,
    useSubmitObjectivesFormMutation,
    useSubmitTrialFormMutation,
    usePostPaypalMutation,
    useLazyGetPlansQuery,
    useLazyGetWinbackPlansQuery,
} = apiSlice;
