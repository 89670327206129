import React, { ReactNode } from 'react';

import './styles.less';

type PageProps = {
    Header?: ReactNode;
    MainMenu?: ReactNode;
    children?: ReactNode;
};

export default function Page({ children, Header, MainMenu }: PageProps) {
    return (
        <div className="app-page-container">
            {Header}
            {MainMenu}
            {children}
        </div>
    );
}
