import React, { useState, useEffect } from 'react';
import { GlobalOutlined } from '@ant-design/icons';
import { Tooltip, Button, Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';

import i18n, { languages } from '@Shared/utils/i18n';
import useLocalStorage from '@Shared/utils/useLocalStorage';

import type { MenuProps } from 'antd';
import type { ItemType } from 'antd/es/menu/interface';

export default function LanguageDropdown() {
    const { t } = useTranslation();
    const storage = useLocalStorage();
    const [currentLanguage, setCurrentLanguage] = useState<string>(storage.read('currentLanguage', i18n.language));
    const items: MenuProps['items'] = languages.map((l) => ({ key: l.key, label: <>{l.label}</> }));

    useEffect(() => {
        setTimeout(() => {
            i18n.changeLanguage(currentLanguage as string);
        }, 0);
    }, [currentLanguage]);

    const handleLanguageChange = (selected: ItemType) => {
        if (selected && selected.key) {
            storage.write('currentLanguage', selected.key as string);
            setCurrentLanguage(selected.key as string);
        }
    };

    return (
        <Dropdown
            trigger={['click']}
            menu={{
                items,
                selectable: true,
                defaultSelectedKeys: [i18n.language],
                onSelect: handleLanguageChange,
            }}
            placement="bottomRight"
        >
            <Tooltip
                title={t('signup.select_language')}
                placement="left"
            >
                <Button icon={<GlobalOutlined />}>{languages.find((l) => l.key === currentLanguage)!.label}</Button>
            </Tooltip>
        </Dropdown>
    );
}
