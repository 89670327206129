import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export default function useLocalStorage(path: string = 'default') {
    const location = useLocation();
    const isWinback = useMemo(() => location.pathname.includes('lovely-user/winback'), [location.pathname]);

    const storagePath = isWinback ? 'winback' : path;

    const read = (key: string, defaultValue?: unknown) => {
        const storageKey = `${storagePath}.${key}`;
        const item = localStorage.getItem(storageKey);
        const parsedItem = item ? JSON.parse(item) : undefined;
        const result = parsedItem || defaultValue;
        return result;
    };

    const write = (key: string, value: unknown) => {
        const storageKey = `${storagePath}.${key}`;
        localStorage.setItem(storageKey, JSON.stringify(value));
    };

    const remove = (key: string) => {
        const storageKey = `${storagePath}.${key}`;
        localStorage.removeItem(storageKey);
    };

    const storage = {
        read,
        write,
        remove,
    };

    return storage;
}
