import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, Input, Button, Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useIntercom } from 'react-use-intercom';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { useSubmitAuthFormMutation } from '@Entities/signup/api/signupSlice';
import { ga4event } from '@Shared/utils/ga4event';
import useYup from '@Shared/utils//useYup';
import useLocalStorage from '@Shared/utils/useLocalStorage';

type AuthFormDataType = {
    firstName: string;
    email: string;
    password: string;
    notificationsAgree?: boolean;
    termsAgree: boolean;
};

export default function AuthForm({ handleStep }: { handleStep: (arg1: number) => void }) {
    const storage = useLocalStorage();
    const { t } = useTranslation('translation', { keyPrefix: 'signup' });
    const { trackEvent } = useIntercom();
    const [submitAuthForm, { isLoading }] = useSubmitAuthFormMutation();
    const [errors, setErrors] = useState<Partial<AuthFormDataType>>({});
    const [form] = Form.useForm();
    const yupRules = useYup({
        firstName: yup.string().required(t('form.error.enter_name')),
        email: yup.string().required(t('form.error.enter_email')).email(t('form.error.email_incorrect')),
        password: yup.string().required(t('form.error.enter_password')).min(8, t('form.error.password_short')),
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement> | CheckboxChangeEvent) => {
        const fieldName = e.target?.id?.replace('auth-form_', '');
        ga4event({
            event_name: `enter_${fieldName}`,
            event_option1: 'advertiser registration funnel',
            event_option2: 'filled name email password',
        });
        setErrors((prev: Partial<AuthFormDataType>) =>
            Object.fromEntries(
                Object.entries(prev).filter(([key]: [key: string, value: unknown]) => key !== fieldName),
            ),
        );
    };

    const handleSubmit = async (formData: AuthFormDataType) => {
        try {
            const res = await submitAuthForm(formData);
            if (res?.error) {
                const errors = (res?.error?.data?.errors as AuthFormDataType) || {};
                ga4event({
                    event_name: 'send_advertiser_signup_lead_success_f',
                    event_option1: 'advertiser registration funnel',
                    event_option2: 'filled name email password',
                    event_option4: `error ${Object.values(errors).join(' ')}`,
                });
                trackEvent('lead-from-signup-step-1', {
                    eventMessage: 'auth step error',
                    formData,
                });

                setErrors(errors);
            } else {
                ga4event({
                    event_name: 'send_advertiser_signup_lead_success_f',
                    event_option1: 'advertiser registration funnel',
                    event_option2: 'filled name email password',
                });

                trackEvent('lead-from-signup-step-1', {
                    eventMessage: 'auth step success',
                    formData,
                });

                storage.write('currentStep', 'CompanyStep');
                storage.write('stepUuid', res?.data?.uuid || '');
                storage.write('authStepData', formData || {});
                handleStep(1);
            }
        } catch (err) {
            console.error('error: ', err);
        }
    };

    return (
        <Form
            initialValues={storage.read('authStepData', {
                firstName: '',
                email: '',
                password: '',
            })}
            name="auth-form"
            layout="vertical"
            form={form}
            onFinish={handleSubmit}
            requiredMark={false}
        >
            <Form.Item
                label={t('auth_form.name')}
                name="firstName"
                rules={yupRules}
                validateStatus={errors?.firstName ? 'error' : ''}
                help={errors?.firstName || null}
            >
                <Input
                    size="large"
                    onBlur={handleChange}
                    className="signup-input"
                />
            </Form.Item>
            <Form.Item
                label={t('auth_form.email')}
                name="email"
                rules={yupRules}
                validateStatus={errors?.email ? 'error' : ''}
                help={errors?.email || null}
            >
                <Input
                    size="large"
                    onBlur={handleChange}
                    className="signup-input"
                    onInput={(e: React.FormEvent<HTMLInputElement>) =>
                        (e.target as HTMLInputElement).value
                            ? ((e.target as HTMLInputElement).value = (
                                  e.target as HTMLInputElement
                              ).value.toLowerCase())
                            : ''
                    }
                />
            </Form.Item>
            <Form.Item
                label={t('auth_form.password')}
                name="password"
                rules={yupRules}
                extra={!errors?.password ? t('form.hint.password_short') : null}
                validateStatus={errors?.password ? 'error' : ''}
                help={errors?.password || null}
            >
                <Input.Password
                    size="large"
                    onBlur={handleChange}
                    className="signup-input"
                />
            </Form.Item>
            <Form.Item
                className="item-custom-checkbox"
                rootClassName="item-custom-root-checkbox"
                name="notificationsAgree"
                validateStatus={errors?.notificationsAgree ? 'error' : ''}
                help={errors?.notificationsAgree || null}
                valuePropName="checked"
            >
                <Checkbox
                    className="checkbox-custom"
                    onChange={handleChange}
                >
                    {t('auth_form.agree_notiication')}
                </Checkbox>
            </Form.Item>
            <Form.Item
                name="termsAgree"
                validateStatus={errors?.termsAgree ? 'error' : ''}
                help={errors?.termsAgree || null}
                valuePropName="checked"
                rules={[
                    {
                        required: true,
                        transform: (value) => value || undefined,
                        type: 'boolean',
                        message: t('auth_form.terms_agree'),
                    },
                ]}
            >
                <Checkbox onChange={handleChange}>
                    {t('auth_form.i_accept')}{' '}
                    <Link
                        relative="path"
                        to="https://tapfiliate.com/terms/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t('auth_form.terms_conditions')}
                    </Link>{' '}
                    {t('auth_form.and')}{' '}
                    <Link
                        relative="path"
                        to="https://tapfiliate.com/privacy/privacy-policy/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t('auth_form.privacy_policy')}
                    </Link>{' '}
                    {t('auth_form.news_agree')}{' '}
                    <Link
                        relative="path"
                        to="https://tapfiliate.com/general-data-protection-regulation-gdpr/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t('auth_form.learn_gdpr')}{' '}
                    </Link>
                </Checkbox>
            </Form.Item>
            <Form.Item style={{ margin: 0 }}>
                <Button
                    block
                    htmlType="submit"
                    type="primary"
                    style={{ textTransform: 'uppercase', fontWeight: 600 }}
                    size="large"
                    loading={isLoading}
                >
                    {t('auth_form.try_free')}
                </Button>
            </Form.Item>
        </Form>
    );
}
