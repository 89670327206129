import { configureStore, combineReducers } from '@reduxjs/toolkit';

import notificationsReducer from '@Entities/notifications/model/notificationSlice';
import { apiSlice } from '@Entities/signup/api/signupSlice';

import { rtkQueryErrorLogger } from './middleware';

const rootReducer = combineReducers({
    notifications: notificationsReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(rtkQueryErrorLogger),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
