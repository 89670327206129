import React from 'react';
import { Typography, Row, Col, Grid } from 'antd';
// import Icon from '@ant-design/icons';

import { AuthForm } from '@Features/index';
import { AdvCard } from '@Widgets/index';
// import { Logo, Avatar, GoogleIcon } from '@Shared/assets/images';
import { Avatar } from '@Shared/assets/images';

const { Title } = Typography;
const { useBreakpoint } = Grid;

const customTokens = {
    colorSurfaceBlueBg: '#D4E8FF',
    colorButtonDefaultBlue: '#0085FF',
};

const styles: Record<string, React.CSSProperties> = {
    main: {
        maxWidth: 'min(calc(100vw - 20px), 1400px)',
        width: 'fit-content',
        height: 'fit-content',
    },
    buttonsSpace: {
        width: '100%',
    },
    orDivider: {
        margin: 10,
        textAlign: 'center',
    },
    em: {
        color: customTokens.colorButtonDefaultBlue,
        fontStyle: 'normal',
    },
};

export default function AuthStep({ handleStep }: { handleStep: (arg1: number) => void }) {
    const breakpoints = useBreakpoint();

    return (
        <Row style={styles.main}>
            <Col
                lg={14}
                md={24}
                sm={24}
                xs={24}
            >
                <div style={{ padding: breakpoints.lg ? '40px 60px' : 20 }}>
                    <Title
                        level={4}
                        style={{ marginBottom: 20 }}
                    >
                        Join us today: it&apos;s fast and simple!
                    </Title>

                    <AuthForm handleStep={handleStep} />

                    {/* <p style={styles.orDivider}>Or</p>
                    <Button
                        size="large"
                        style={{ textTransform: 'uppercase', fontWeight: 600 }}
                        icon={
                            <Icon
                                component={() => (
                                    <img
                                        src={GoogleIcon}
                                        alt="google-icon"
                                    />
                                )}
                            />
                        }
                        block
                    >
                        Try it for free via Google
                    </Button> */}
                </div>
            </Col>

            <Col
                lg={10}
                md={0}
                sm={0}
                xs={0}
            >
                <AdvCard
                    avatar={Avatar}
                    author="Henri Liljeroos"
                    authorPosition="CEO/Founder at Campwire"
                >
                    <em style={styles.em}>It&apos;s so easy to use that it almost makes me cry.</em> We tried many
                    affiliate platforms, they were all either complicated, outdated, or expensive—often all three.
                    Tapfiliate&apos;s simplicity still amazes me!
                </AdvCard>
            </Col>
        </Row>
    );
}
